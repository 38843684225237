<template>
	<div>
		<el-tabs type="border-card">
			<el-tab-pane label="病历">
				<el-form :model="recordInfo" :rules="rules" ref="recordInfo" label-width="100px" class="container">
					<div class="infoBox" style="justify-content: space-between;">
						<h3 class="title">基础信息</h3>
						<div class="infoBox" style="height:30px">
							<el-button size="small" type="primary" icon="el-icon-document-copy" @click="
                  onGetMedical(), (dialogFormVisible = true), (setStatus = 5)
                ">病历模板</el-button>
							<el-button size="small" type="primary" icon="el-icon-document-checked"
								@click="saveTemplate(1)">存模板</el-button>
						</div>
					</div>

					<div class="physiqueInfo">
						<div style="display:flex">
							<el-form-item label="就诊人">
								<el-input v-model="userData.names" v-if="userData" disabled placeholder="请选择">
								</el-input>
								<el-select v-model="userData.names" v-else placeholder="请选择" class="handle-select mr10">
									<el-option v-for="(item, index) in userDataList" :key="index" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<div class="basics" v-if="userData">
								<el-form-item label="性别：">
									<span>{{ userData.sex }}</span>
								</el-form-item>
								<el-form-item label="年龄：">
									<span>{{ userData.age }}</span>
								</el-form-item>
								<el-form-item label="联系电话：">
									<span>{{ userData.mobile }}</span>
								</el-form-item>
								<el-form-item label="居住地址：">
									<span>{{ userData.address }}</span>
								</el-form-item>
							</div>
						</div>
						<div style="display:flex">
							<el-form-item label="看诊项目" v-if="reload">
								<el-select v-model="pro_inx" placeholder="看诊项目" multiple class="handle-select mr10"
									@change="onProject" style="width: 150px;">
									<el-option v-for="(item) in projectList" :key="item.id" :label="item.name"
										:value="item.id"/>
<!-- 									<el-option v-for="(item) in projectList" :key="item.id" :label="item.name"
										:value="item.id" @click='chooseThisOne(item)' /> -->
								</el-select>
								<el-button style="display: inline-block;margin-left: 10px;" type=""
									@click='addProjectVisible = true'>添加</el-button>
							</el-form-item>
							<el-form-item label="">
								<el-select v-model="other.type" placeholder="请选择">
									<el-option label="初诊" :value="1"></el-option>
									<el-option label="复诊" :value="2"></el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="症状">
								<el-input style="width:400px" v-model="other.symptom" placeholder=""></el-input>
							</el-form-item> -->
							<el-form-item label="看诊日期"> <el-date-picker
									v-model="other.symptom"
									type="date"
									placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</div>
					</div>

					<h3 class="title">体格信息</h3>
					<div class="box">
						<el-form-item label="身高">
							<el-col :span="12">
								<el-input v-model="physiques.height"
									onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" maxlength="8"
									placeholder=""></el-input>
							</el-col>
							<el-col class="line" :span="4">cm</el-col>
						</el-form-item>
						<el-form-item label="体重">
							<el-col :span="12">
								<el-input v-model="physiques.weight"
									onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" maxlength="8"
									placeholder=""></el-input>
							</el-col>
							<el-col class="line" :span="4">kg</el-col>
						</el-form-item>
						<el-form-item label="血压">
							<el-col :span="12">
								<el-input v-model="physiques.blood_pressure"></el-input>
							</el-col>
							<el-col class="line" :span="4">mmHg</el-col>
						</el-form-item>
						<el-form-item label="脉搏">
							<el-col :span="12">
								<el-input v-model="physiques.sphygmus"></el-input>
							</el-col>
							<el-col class="line" :span="4">次/分</el-col>
						</el-form-item>
					</div>
					<h3 class="title">病历信息</h3>
					<div class="infoBox">
						<div class="infoBoxLeft">
							<el-form-item label="主诉" prop="appeal" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.appeal"></el-input>
							</el-form-item>
							<el-form-item label="现病史" prop="now" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.now"></el-input>
							</el-form-item>
							<el-form-item label="既往史" prop="before" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.before"></el-input>
							</el-form-item>
							<el-form-item label="体格检查" prop="physique" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.physique"></el-input>
							</el-form-item>
							<el-form-item label="诊断" prop="diagnose" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.diagnose"></el-input>
							</el-form-item>
							<el-form-item label="治疗方案" prop="treatment" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.treatment"></el-input>
							</el-form-item>
							<el-form-item label="治疗过程" label-width="120px">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.msg"></el-input>
							</el-form-item>
							<el-form-item label="诊后注意事项" label-width="120px" prop="announcements">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.announcements"></el-input>
							</el-form-item>
						</div>
						<div class="infoBoxRight">
							<el-form-item label="远程会诊意见">
								<el-input class="textarea" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
									v-model="recordInfo.remote_views"></el-input>
							</el-form-item>
							<h5 style="margin:20px 0">评分表</h5>
							<div class="linkWrap">
								<el-button class="buttonText" v-for="(item, index) in scoreList" :key="index"
									@click="onScoring(item)" type="text">{{ item.name
                  }}{{
                    !item.total_points == 0 ? "(" + item.total_points + ")" : ""
                  }}</el-button>
							</div>
						</div>
					</div>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="诊疗单">
				<el-form label-width="100px" class="container">
					<div class="infoBox" style="justify-content: space-between;">
						<h3 class="title">基础信息</h3>
						<div class="infoBox" style="height:30px">
							<el-button size="small" type="primary" icon="el-icon-setting"
								@click="(dialogFormVisible = true), (setStatus = 1)">疗程设置</el-button>
							<el-button size="small" type="primary" icon="el-icon-document-copy" @click="
                  seeTemplate(), (dialogFormVisible = true), (setStatus = 4)
                ">诊疗/药品模板</el-button>
							<el-button size="small" type="primary" icon="el-icon-document-checked"
								@click="saveTemplate(2)">存模板</el-button>
						</div>
					</div>

					<div class="physiqueInfo">
						<div style="display:flex">
							<el-form-item label="就诊人">
								<el-input v-model="userData.names" v-if="userData" disabled placeholder="请选择">
								</el-input>
								<el-select v-model="userData.names" v-else placeholder="请选择" class="handle-select mr10">
									<el-option v-for="(item, index) in userDataList" :key="index" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<div class="basics" v-if="userData">
								<el-form-item label="性别：">
									<span>{{ userData.sex }}</span>
								</el-form-item>
								<el-form-item label="年龄：">
									<span>{{ userData.age }}</span>
								</el-form-item>
								<el-form-item label="联系电话：">
									<span>{{ userData.mobile }}</span>
								</el-form-item>
								<el-form-item label="居住地址：">
									<span>{{ userData.address }}</span>
								</el-form-item>
							</div>
						</div>
						<div style="display:flex">
							<el-form-item label="看诊项目" v-if="reload">
								<el-select v-model="pro_inx" placeholder="看诊项目" multiple class="handle-select mr10"
									@change="onProject" style="width: 150px;">
									<el-option v-for="(item) in projectList" :key="item.id" :label="item.name"
										:value="item.id"/>
<!-- 									<el-option v-for="(item) in projectList" :key="item.id" :label="item.name"
										:value="item.id" @click='chooseThisOne(item)' /> -->
								</el-select>
								<el-button style="display: inline-block;margin-left: 10px;" type=""
									@click='addProjectVisible = true'>添加</el-button>
							</el-form-item>
							<el-form-item label="">
								<el-select v-model="other.type" placeholder="请选择">
									<el-option label="初诊" :value="1"></el-option>
									<el-option label="复诊" :value="2"></el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="症状">
								<el-input style="width:400px" v-model="other.symptom" placeholder=""></el-input>
							</el-form-item> -->
							<el-form-item label="看诊日期">
								<el-date-picker
								v-model="other.symptom"
								type="date"
								placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</div>
					</div>

					<div class="infoBox">
						<div class="infoBoxLeft">
							<h3 class="title">
								<span>诊疗单：</span>
								<el-button class="mark-btn" type="" @click='markupTreamentVisible = true'>备注诊疗
								</el-button>
							</h3>
							<el-table :data="orderData1" style="width: 100%;margin-bottom:20px;">
								<el-table-column type="index" width="60" label="序号">
								</el-table-column>
								<el-table-column prop="name" label="诊疗项目">
								</el-table-column>
								<el-table-column prop="specification" label="规格型号">
								</el-table-column>
								<el-table-column prop="unit" label="单位">
								</el-table-column>
								<!-- <el-table-column prop="msg" label="备注"> </el-table-column> -->
								<el-table-column prop="counts" label="数量" width="150">
									<template #default="scope">
										<el-input-number size="small" v-model="scope.row.counts" @change="handleChange"
											:min="1" :max="99" label="输入数量"></el-input-number>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="150">
									<template #default="scope">
										<el-button @click.prevent="deleteRow1(scope.$index, orderData1)" type="text"
											size="small">移除
										</el-button>
									</template>
								</el-table-column>
							</el-table>

							<h3 class="title">
								<span>药品单：</span>
								<el-button type="" class="mark-btn" @click='markupMedicineVisible = true'>备注药品
								</el-button>
							</h3>
							<el-table :data="orderData2" style="width: 100%;margin-bottom:20px;">
								<el-table-column type="index" width="60" label="序号"></el-table-column>
								<el-table-column prop="medicine_name" label="药品名称"></el-table-column>
								<el-table-column prop="specification" label="规格型号"></el-table-column>
								<el-table-column prop="unit" label="单位"></el-table-column>
								<!-- <el-table-column prop="msg" label="备注"></el-table-column> -->
								<el-table-column prop="counts" label="数量" width="150">
									<template #default="scope">
										<el-input-number size="small" v-model="scope.row.counts" @change="handleChange2"
											:min="1" :max="scope.row.count" label="输入数量"></el-input-number>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="150">
									<template #default="scope">
										<el-button @click.prevent="deleteRow2(scope.$index, orderData2)" type="text"
											size="small">移除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
							<h3 class="title">体检单：</h3>
							<el-table :data="orderData4" style="width: 100%;margin-bottom:20px;">
								<el-table-column type="index" width="60" label="序号"></el-table-column>
								<el-table-column prop="name" label="体检名称"></el-table-column>
								<el-table-column prop="price" label="价格"></el-table-column>
								<!-- <el-table-column prop="msg" label="临床意义"></el-table-column> -->
								<el-table-column label="操作" width="150">
									<template #default="scope">
										<el-button @click.prevent="deleteRow4(scope.$index, orderData4)" type="text"
											size="small">移除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
							<h3 class="title">会员服务：</h3>
							<el-table :data="orderData3" style="width: 100%;margin-bottom:20px;">
								<el-table-column type="index" width="60" label="序号"></el-table-column>
								<el-table-column prop="name" label="服务名称">
								</el-table-column>
								<el-table-column prop="units" label="单位">
								</el-table-column>
								<!-- <el-table-column prop="msg" label="备注"> </el-table-column> -->
								<el-table-column prop="counts" label="数量" width="150">
									<template #default="scope" width="150">
										<el-input-number size="small" v-model="scope.row.counts" @change="handleChange3"
											:min="1" :max="99" label="输入数量"></el-input-number>
									</template>
								</el-table-column>
								<el-table-column label="操作">
									<template #default="scope">
										<el-button @click.prevent="deleteRow3(scope.$index, orderData3)" type="text"
											size="small">移除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="infoBoxRight">
							<el-tabs v-model="activeIndex" @tab-click="handleTab">
								<el-tab-pane name="1" label="诊疗">
									<el-select v-model="categorys_id" clearable style="margin-bottom: 15px"
										placeholder="请选择诊疗项目" @change="onGetDiagnosis">
										<el-option v-for="(item, index) in dtClass" :key="index" :label="item.name"
											:value="item.id"></el-option>
									</el-select>
									<el-input placeholder="请输入诊疗项目名称"
										style="width:200px; margin:0 10px; margin-bottom: 15px;"
										v-model="categorys_name" clearable></el-input>
									<el-button type="primary" @click="onGetDiagnosis" @keyup.enter="onkey">搜索
									</el-button>
									<el-table ref="multipleTable1" :row-key="changecs1" :data="dtClassList"
										:row-class-name="tableRowClassName" @row-click="rowClick1" tooltip-effect="dark"
										style="width: 100%" @selection-change="dtClassChange">
										<el-table-column type="selection" width="55" :reserve-selection="true">
										</el-table-column>
										<el-table-column prop="name" label="诊疗项目"></el-table-column>
										<el-table-column prop="specification" label="规格型号"></el-table-column>
										<el-table-column prop="msg" label="备注"></el-table-column>
									</el-table>
									<div class="pagination">
										<el-pagination background layout="total, prev, pager, next"
											:current-page="dtPage" :page-size="limt" :total="dtClassTotal"
											@current-change="dtPageChange">
										</el-pagination>
									</div>
								</el-tab-pane>
								<el-tab-pane name="2" label="药品">
									<el-select v-model="categorys_type" style="margin-bottom: 15px" clearable
										placeholder="请选择药品分类" @change="onGetMedicine">
										<el-option label="耗材" value="耗材"></el-option>
										<el-option label="药品" value="药品"></el-option>
										<el-option label="保健品" value="保健品"></el-option>
									</el-select>
									<el-input placeholder="请输入药品名称"
										style="width:200px; margin:0 10px; margin-bottom: 15px;"
										v-model="categorys_name2" clearable></el-input>
									<el-button type="primary" @click="onGetMedicine" @keyup.enter="onkey2">搜索
									</el-button>
									<el-table ref="multipleTable2" :row-key="changecs2" :data="drugsList"
										:row-class-name="tableRowClassName" @row-click="rowClick2" tooltip-effect="dark"
										style="width: 100%" @selection-change="dtClassChange2">
										<el-table-column type="selection" width="55" :reserve-selection="true">
										</el-table-column>
										<el-table-column prop="medicine_name" label="药品名称"></el-table-column>
										<el-table-column prop="specification" label="规格型号"></el-table-column>
										<el-table-column prop="sell" label="价格（元）" width=""></el-table-column>
										<el-table-column prop="count" label="库存" width=""></el-table-column>
									</el-table>
									<div class="pagination">
										<el-pagination background layout="total, prev, pager, next"
											:current-page="ypPage" :page-size="limt" :total="drugsTotal"
											@current-change="ypPageChange">
										</el-pagination>
									</div>
								</el-tab-pane>
								<el-tab-pane name="4" label="体检">
									<el-input placeholder="请输入体检名称"
										style="width:200px; margin:0 10px; margin-bottom: 15px;"
										v-model="categorys_name4" clearable></el-input>
									<el-button type="primary" @click="onGetExamination" @keyup.enter="onkey4">搜索
									</el-button>
									<el-table ref="multipleTable4" :data="examination" :row-key="changecs4"
										:row-class-name="tableRowClassName" @row-click="rowClick4" tooltip-effect="dark"
										style="width: 100%" @selection-change="dtClassChange4">
										<el-table-column type="selection" width="55" :reserve-selection="true">
										</el-table-column>
										<el-table-column prop="name" label="体检名称"></el-table-column>
										<el-table-column prop="price" label="价格"></el-table-column>
										<el-table-column prop="msg" label="临床意义" width="300"></el-table-column>
									</el-table>
									<div class="pagination">
										<el-pagination background layout="total, prev, pager, next"
											:current-page="exPage" :page-size="limt" :total="examinationTotal"
											@current-change="examinationPageChange"></el-pagination>
									</div>
								</el-tab-pane>
								<el-tab-pane name="3" label="会员服务">
									<el-input placeholder="请输入服务名称"
										style="width:200px; margin:0 10px; margin-bottom: 15px;"
										v-model="categorys_name3" clearable></el-input>
									<el-button type="primary" @click="onVipServe" @keyup.enter="onkey3">搜索</el-button>
									<el-table ref="multipleTable3" :data="vipList" :row-key="changecs3"
										:row-class-name="tableRowClassName" tooltip-effect="dark" style="width: 100%"
										@row-click="rowClick3" @selection-change="dtClassChange3">
										<el-table-column type="selection" width="55" :reserve-selection="true">
										</el-table-column>
										<el-table-column prop="name" label="服务名称"></el-table-column>
										<el-table-column prop="units" label="单位"></el-table-column>
										<el-table-column prop="msg" label="备注"></el-table-column>
									</el-table>
									<div class="pagination">
										<el-pagination background layout="total, prev, pager, next"
											:current-page="VipPage" :page-size="limt" :total="vipTotal"
											@current-change="vipPageChange">
										</el-pagination>
									</div>
								</el-tab-pane>
								<!-- <el-button style="margin-top:20px" type="primary" @click="addPro">确认添加</el-button> -->
							</el-tabs>
						</div>
					</div>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<div class="container footer">
			<div class="containerLeft">
				<div class="containerLeft_text">
					治疗：<span>{{ getCureMoney }}</span>元
				</div>
				<div class="containerLeft_text">
					药品：<span>{{ getDrugsMoney }}</span>元
				</div>
				<div class="containerLeft_text">
					体检：<span>{{ getExaminationMoney }}</span>元
				</div>
				<div class="containerLeft_text">
					会员服务：<span>{{ getMemberMoney }}</span>元
				</div>
				<div class="containerLeft_text">
					总计：<span>{{ totalMoney }}</span>元
				</div>
			</div>
			<el-button type="primary" @click="onSubmit">确定</el-button>
		</div>
		<el-dialog :title="
        setStatus == 5
          ? '病历模板'
          : setStatus == 3
          ? '详情'
          : setStatus == 2
          ? '模板信息'
          : setStatus == 1
          ? '疗程设置'
          : '诊疗/处方模板'
      " v-model="dialogFormVisible">
			<el-form v-if="setStatus == 2" :model="form2" label-width="100px">
				<el-form-item label="创建人：">
					<span>{{ getUserName }}</span>
				</el-form-item>
				<el-form-item label="模板名称：">
					<el-input v-model="nameTemplate1" width="200px" placeholder=""></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onAddMedical">确 定</el-button>
				</el-form-item>
			</el-form>
			<el-form v-else-if="setStatus == 1" :model="form">
				<el-form-item v-for="(item, index) in course" :key="index" :label="'第' + (index + 1) + '次'">
					<el-date-picker v-model="item.date" value-format="YYYY-MM-DD" type="date" placeholder="选择日期"
						@change="handleDate"></el-date-picker>
					<el-button style="margin-left:10px" icon="el-icon-plus" @click="addClick(index)" circle></el-button>
					<el-button style="margin-left:10px" icon="el-icon-minus" @click="delClick(index)" v-if="index > 0"
						circle></el-button>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
				</el-form-item>
			</el-form>
			<el-table v-else-if="setStatus == 3" :data="caseHistoryInfo" style="width: 100%;margin-bottom:20px;">
				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="content" label="内容"></el-table-column>
			</el-table>
			<el-table v-else-if="setStatus == 6" :data="templateInfo" style="width: 100%; margin-bottom:20px;">
				<el-table-column type="index" width="50" label="序号"></el-table-column>
				<el-table-column prop="name" label="治疗项目"></el-table-column>
				<el-table-column prop="specification" label="规格型号">
				</el-table-column>
				<el-table-column prop="unit" label="单位"> </el-table-column>
				<el-table-column prop="counts" label="数量"></el-table-column>
				<el-table-column prop="msg" label="备注"> </el-table-column>
			</el-table>
			<div v-else-if="setStatus == 5">
				<el-table :data="caseHistoryList" border style="width: 100%">
					<el-table-column align="center" type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column align="center" prop="id" label="模板编号">
					</el-table-column>
					<el-table-column align="center" prop="name" label="模板名称">
					</el-table-column>
					<el-table-column align="center" prop="disease_name" label="看诊项目">
					</el-table-column>
					<el-table-column align="center" prop="create_time" label="创建日期">
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template #default="scope">
							<el-button type="text" icon="el-icon-folder-opened" @click="
                  onMedicalDetails(scope.row.id, 1), (dialogFormVisible = false)
                ">调用</el-button>
							<el-button type="text" icon="el-icon-tickets" @click="
                  onMedicalDetails(scope.row.id, 2),
                    (dialogFormVisible = true),
                    (setStatus = 3)
                ">详情</el-button>
							<el-popconfirm title="是否确定删除该模板？" @confirm="onDelMedical(scope.row.id, 1)">
								<template #reference>
									<el-button type="text" icon="el-icon-delete">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination">
					<el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="limt"
						:total="caseHistoryTotal" @current-change="casePageChange"></el-pagination>
				</div>
			</div>

			<el-tabs v-else v-model="activeType" type="card">
				<el-tab-pane label="治疗" name="1">
					<el-table :data="templateData1" border style="width: 100%">
						<el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
						<el-table-column align="center" prop="id" label="模板编号">
						</el-table-column>
						<el-table-column align="center" prop="name" label="模板名称">
						</el-table-column>
						<el-table-column align="center" prop="disease_name" label="看诊项目">
						</el-table-column>
						<el-table-column align="center" prop="create_time" label="创建日期">
						</el-table-column>
						<el-table-column label="操作" width="200">
							<template #default="scope">
								<el-button type="text" icon="el-icon-document-copy" @click="
                    onDiagnosisInfo(scope.row.id, 1),
                      (dialogFormVisible = false)
                  ">调用</el-button>
								<el-button type="text" icon="el-icon-tickets" @click="
                    onDiagnosisInfo(scope.row.id, 2),
                      (dialogFormVisible = true),
                      (setStatus = 6)
                  ">详情</el-button>
								<el-popconfirm title="是否确定删除该模板？" @confirm="onDelMedical(scope.row.id, 2)">
									<template #reference>
										<el-button type="text" icon="el-icon-delete">删除</el-button>
									</template>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, prev, pager, next" :current-page="page"
							:page-size="limt" :total="templateTotal1" @current-change="seeTemplate1"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="处方" name="2">
					<el-table :data="templateData2" border style="width: 100%">
						<el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
						<el-table-column align="center" prop="id" label="模板编号">
						</el-table-column>
						<el-table-column align="center" prop="name" label="模板名称">
						</el-table-column>
						<el-table-column align="center" prop="disease_name" label="看诊项目">
						</el-table-column>
						<el-table-column align="center" prop="create_time" label="创建日期">
						</el-table-column>
						<el-table-column label="操作" width="200">
							<template #default="scope">
								<el-button type="text" icon="el-icon-document-copy" @click="
                    onDiagnosisInfo(scope.row.id, 1),
                      (dialogFormVisible = false)
                  ">调用</el-button>
								<el-button type="text" icon="el-icon-tickets" @click="
                    onDiagnosisInfo(scope.row.id, 2),
                      (dialogFormVisible = true),
                      (setStatus = 6)
                  ">详情</el-button>
								<el-popconfirm title="是否确定删除该模板？" @confirm="onDelMedical(scope.row.id, 2)">
									<template #reference>
										<el-button type="text" icon="el-icon-delete">删除</el-button>
									</template>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, prev, pager, next" :current-page="page"
							:page-size="limt" :total="templateTotal2" @current-change="seeTemplate2"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>

		<el-dialog v-model="addProjectVisible" title="添加看诊项目" width="400px" :before-close="handleCloseAddPorject">
			<div class="" style="display: flex;margin-top: 8px;" v-for="(item,index) in addProjectNameList"
				:key="index">
				<el-input style="display: inline-block;width: 312px;" v-model="addProjectNameList[index]"
					placeholder="请输入项目名"></el-input>
				<div class="delete-proj-btn" v-if="index !== 0" @click='deleteProjectItem(index)'>
					<i class="el-icon-delete"></i>
				</div>
			</div>
			<el-button @click='addProjectNameItem' style="margin-top: 8px;">添加</el-button>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addProjectVisible = false">取消</el-button>
					<el-button type="" @click="addProject">添加</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog v-model="markupTreamentVisible" title="备注诊疗" width="30%">
			<textarea class='markup-input' v-model="markupTreament" rows="" cols="" style=""
				placeholder="请输入备注内容"></textarea>
			<template #footer>
				<span class="dialog-footer">
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button type="primary" @click="markupTreamentVisible = false">确定</el-button>
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="markupMedicineVisible" title="备注药品" width="30%">
			<textarea class='markup-input' v-model="markupMedicine" rows="" cols="" style=""
				placeholder="请输入备注内容"></textarea>
			<template #footer>
				<span class="dialog-footer">
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button type="primary" @click="markupMedicineVisible = false">确定</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
</template>

<script>
	import {
		h
	} from "vue";
	import {
		getClassify,
		getDiagnosis,
		getMedicine,
		getExamination,
		vipServe,
		registrationInfo,
		addMedical,
		getDisease,
		getMedical,
		medicalDetails,
		delMedical,
		addDiagnosis,
		addDrug,
		diagnosisDrug,
		diagnosisDrugs,
		delTemplate,
		clinicalReception,
		diagnosisDetails,
		editOrder,
		addWatchTreament,
	} from "../../api/index";
	export default {
		name: "registerCase",
		data() {
			return {
				reload: true,
				addProjectVisible: false,
				addProjectNameList: [''],
				markupTreamentVisible: false,
				markupTreament: '',
				markupMedicineVisible: false,
				markupMedicine: '',

				id: "", //诊疗服务单表id
				types: 1, // types 1：接诊  2：编辑接诊
				dtClass: [], //诊疗分类
				categorys_id: "",
				categorys_name: "",
				categorys_name2: "",
				categorys_name3: "",
				categorys_name4: "",
				dtClassList: [], //诊疗列表
				page: 1,
				dtPage: 1,
				ypPage: 1,
				exPage: 1,
				VipPage: 1,
				limt: 10,
				dtClassTotal: 0,
				categorys_type: "", //药品分类
				drugsList: [],
				drugsTotal: 0,
				examination: [],
				examinationTotal: 0,
				vipList: [], //会员服务
				vipTotal: 0,
				activeIndex: "1", //处方药品切换

				userData: "",
				userDataList: [],
				recordInfo: {
					msg: "",
					remote_views: ""
				}, //病历信息
				nameTemplate1: "", //病历模板名称

				projectList: [], //看诊项目
				pro_inx: [], //看诊项目下标
				disease_ids: [], //看诊项目id
				disease_name: [], //看诊项目名称
				caseHistoryList: [], //病历模板
				caseHistoryTotal: 0, //病历模板总条数
				caseHistoryInfo: [], //病历模板详情
				orderData1: [], //诊疗单
				orderData2: [],
				orderData4: [],
				orderData3: [],
				templateIndex: 1, //模板下标
				activeType: "1", // 诊疗项目/药品模板列表下标
				templateData1: [],
				templateData2: [],
				templateTotal1: 0,
				templateTotal2: 0,
				templateInfo: [], //处方模板详情
				course: [{
					date: ""
				}],
				physiques: {
					//体格信息
					height: "",
					blood_pressure: "",
					sphygmus: "",
					weight: ""
				},
				other: {
					//其他数据
					type: 1, //1代表初诊，2代表复诊
					symptom: "" //症状
				},
				score: [], //评分表数据
				scoreList: [{
						name: "脑卒中",
						number: 1,
						total_points: 0,
						str: {}
					},
					{
						name: "老年性痴呆",
						number: 2,
						total_points: 0,
						str: {}
					},
					{
						name: "脑瘫",
						number: 3,
						total_points: 0,
						str: {}
					},
					{
						name: "帕金森氏病",
						number: 4,
						total_points: 0,
						str: {}
					}
				],

				dialogFormVisible: false,
				setStatus: 1,
				activeName: "first", //模板id

				// 编辑回显数据
				multipleSelection1: [],
				multipleSelection2: [],
				multipleSelection3: [],

				rules: {
					appeal: [{
						required: true,
						message: "请填写主诉",
						trigger: "blur"
					}],
					now: [{
						required: true,
						message: "请填写现病史",
						trigger: "blur"
					}],
					before: [{
						required: true,
						message: "请填写既往史",
						trigger: "blur"
					}],
					physique: [{
						required: true,
						message: "请填写体格检查",
						trigger: "blur"
					}],
					diagnose: [{
						required: true,
						message: "请填写诊断",
						trigger: "blur"
					}],
					treatment: [{
						required: true,
						message: "请填写治疗方案",
						trigger: "blur"
					}],
					announcements: [{
						required: true,
						message: "请填写诊后注意事项",
						trigger: "blur"
					}]
				}
			};
		},
		created() {
			document.addEventListener("keyup", this.onkey);
			document.addEventListener("keyup", this.onkey2);
			document.addEventListener("keyup", this.onkey3);
			document.addEventListener("keyup", this.onkey4);
			this.getPro();
			this.ongetClassify();
			this.onGetDiagnosis();
			this.onGetMedicine();
			this.onGetExamination();
			this.onVipServe();
			let d = new Date();
			this.course[0].date =
				d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

			let userId = this.$route.query.userid;
			this.id = userId;
			this.onRegistrationInfo(userId); //就诊人数据
			// 检测评分表数
			let scoreData = localStorage.getItem("scoreData");
			if (scoreData) {
				let scoreObj = JSON.parse(scoreData);
				this.score.push(scoreObj);
				this.scoreList.map(item => {
					if (item.number == scoreObj.number) {
						item.total_points = scoreObj.total_points;
					}
				});
				localStorage.removeItem("scoreData");
			}
		},
		activated() {},
		watch: {
			tableData(val) {
				val.forEach(item => {
					this.examination.forEach(items => {
						if (item.id === items.id) {
							this.$refs.multipleTable4.toggleRowSelection(item);
						}
					});
				});
				val.forEach(item => {
					this.examination.forEach(items => {
						if (item.id === items.id) {
							this.$refs.multipleTable3.toggleRowSelection(item);
						}
					});
				});
				val.forEach(item => {
					this.examination.forEach(items => {
						if (item.id === items.id) {
							this.$refs.multipleTable2.toggleRowSelection(item);
						}
					});
				});
				val.forEach(item => {
					this.examination.forEach(items => {
						if (item.id === items.id) {
							this.$refs.multipleTable1.toggleRowSelection(item);
						}
					});
				});
			}
		},
		computed: {
			getUserName() {
				//获取专家名字
				return this.$store.state.userInfo.name;
			},
			getCureMoney() {
				//获取治疗费用
				let price = 0;
				if (this.orderData1.length > 0) {
					this.orderData1.map(item => {
						price =
							(price * 100 +
								parseFloat(item.price) * 100 * parseFloat(item.counts)) /
							100;
					});
				}
				return price;
			},
			getDrugsMoney() {
				let price = 0;
				if (this.orderData2.length > 0) {
					this.orderData2.map(item => {
						price =
							(price * 100 +
								parseFloat(item.sell) * 100 * parseFloat(item.counts)) /
							100;
					});
				}
				return price;
			},
			getMemberMoney() {
				let price = 0;
				if (this.orderData3.length > 0) {
					this.orderData3.map(item => {
						price =
							(price * 100 +
								parseFloat(item.price) * 100 * parseFloat(item.counts)) /
							100;
					});
				}
				return price;
			},
			getExaminationMoney() {
				let price = 0;
				if (this.orderData4.length > 0) {
					this.orderData4.map(item => {
						price = (price * 100 + parseFloat(item.price) * 100) / 100;
					});
				}
				return price;
			},
			totalMoney() {
				let price =
					parseFloat(this.getCureMoney) * 100 +
					parseFloat(this.getDrugsMoney) * 100 +
					parseFloat(this.getMemberMoney) * 100 +
					parseFloat(this.getExaminationMoney) * 100;
				price = price / 100;
				return price;
			}
		},
		methods: {
			chooseThisOne(item) {
				console.log(item)
				this.disease_ids.push(item.id);
				this.disease_name.push(item.name);
			},

			handleCloseAddPorject(done) {
				this.addProjectNameList = ['']
				if (done) done()
			},

			deleteProjectItem(index) {
				this.addProjectNameList.splice(index, 1)
			},

			addProjectNameItem() {
				this.addProjectNameList.push('')
			},

			addProjectToInput() {
				this.getPro().then(() => {
					let addProjectNameIdList = this.projectList.filter((pro) => {
						return this.addProjectNameList.indexOf(pro.name) !== -1
					})
					console.log(addProjectNameIdList)
					addProjectNameIdList.forEach((pro) => {
						this.pro_inx.push(pro.id)
						this.disease_ids.push(pro.id);
						this.disease_name.push(pro.name);
					})
					console.log(this.pro_inx)
					this.reload = false
					this.$nextTick(() => {
						this.reload = true
					})
					this.addProjectVisible = false
					this.handleCloseAddPorject()
				}).then(() => {
					// this.onProject()
				})
			},

			addProject() {
				let nameStr = this.addProjectNameList.join(',')
				addWatchTreament({
					name: nameStr
				}).then(() => {
					this.addProjectToInput()
				})
				// this.addProjectVisible = false
			},

			onkey() {
				if (window?.event?.keyCode === 13) {
					this.onGetDiagnosis();
				}
			},
			onkey2() {
				if (window?.event?.keyCode === 13) {
					this.onGetMedicine();
				}
			},
			onkey3() {
				if (window?.event?.keyCode === 13) {
					this.onVipServe();
				}
			},
			onkey4() {
				if (window?.event?.keyCode === 13) {
					this.onGetExamination();
				}
			},
			changecs4(row) {
				return row.id;
			},
			changecs3(row) {
				return row.id;
			},
			changecs2(row) {
				return row.id;
			},
			changecs1(row) {
				return row.id;
			},
			deleteRow1(index, rows) {
				this.toggleSelection1([rows[index]], false);
			},
			deleteRow2(index, rows) {
				this.toggleSelection2([rows[index]], false);
			},
			deleteRow4(index, rows) {
				this.toggleSelection4([rows[index]], false);
			},
			deleteRow3(index, rows) {
				this.toggleSelection3([rows[index]], false);
			},

			rowClick1(row) {
				this.toggleSelection1([row]);
			},
			rowClick2(row) {
				this.toggleSelection2([row]);
			},
			rowClick3(row) {
				this.toggleSelection3([row]);
			},
			rowClick4(row) {
				this.toggleSelection3([row]);
			},
			onRegistrationInfo(id) {
				//获取个人信息
				registrationInfo({
					id
				}).then(res => {
					this.userData = res;
				});
			},
			onEditInfo(id) {
				//获取编辑详情
				// let that = this
				diagnosisDetails({
					id
				}).then(res => {
					console.log('running set msg')
					// this.markupTreament = 'res.diagnosis_msgs'
					// this.markupMedicine = 'res.drug_msgs'
					this.markupTreament = res.diagnosis_msgs
					this.markupMedicine = res.drug_msgs

					this.userData = {
						names: res.name,
						sex: res.sex,
						age: res.age,
						mobile: res.mobile,
						address: res.address,
						info_id: res.info_id
					};
					this.physiques = {
						//体格信息
						height: res.height,
						blood_pressure: res.blood_pressure,
						sphygmus: res.sphygmus,
						weight: res.weight
					};
					this.recordInfo = {
						appeal: res.appeal,
						now: res.now,
						before: res.before,
						physique: res.physique,
						diagnose: res.diagnose,
						treatment: res.treatment,
						announcements: res.announcements,
						msg: res.msg,
						remote_views: res.remote_views
					};
					this.pro_inx = res.disease_ids.map(item => Number(item))
					this.disease_ids = res.disease_ids.map(item => Number(item))
					this.disease_name = res.disease_name.split(',')
					// this.pro_inx = [];
					// this.projectList.map((item, index) => {
					//   res.disease_ids.map(items => {
					//     if (item.id == items) {
					//       this.pro_inx.push(index);
					//     }
					//   });
					// });
					this.onProject(); // 获取看诊项目表id
					this.other.type = res.type;
					// 暂未返回字段
					this.other.symptom = res.symptom; //症状
					// this.getCureMoney = res.diagnosis_cost
					// this.getDrugsMoney = res.drugs_cost
					// this.getMemberMoney = res.vip_cost

					this.course = res.diagnosis_arr;
					this.score = res.score_arr;
					this.scoreList.map(item => {
						res.score_arr.map(items => {
							if (item.number == items.number) {
								item.total_points = items.total_points;
								item.id = items.id;
							}
						});
					});
					this.orderData1 = res.diagnosis_arrs;
					this.orderData2 = res.drug_arr;
					this.orderData3 = res.vip_arr;
					this.orderData4 = res.health_arr;
					this.listEcho(
						res.diagnosis_arrs,
						res.drug_arr,
						res.vip_arr,
						res.health_arr
					);
				});
			},
			listEcho(orderData1, orderData2, orderData3, orderData4) {
				let that = this;
				// 表格数据回显
				that.multipleSelection1 = [];
				that.multipleSelection2 = [];
				that.multipleSelection3 = [];
				that.multipleSelection4 = [];
				that.dtClassList.forEach(row => {
					if (orderData1) {
						orderData1.forEach(item => {
							if (row.id == item.diagnosis_id) {
								that.multipleSelection1.push(row);
							}
						});
					}
				});
				that.drugsList.forEach(row => {
					if (orderData2) {
						orderData2.forEach(item => {
							if (row.id == item.medicine_id) {
								that.multipleSelection2.push(row);
							}
						});
					}
				});
				that.vipList.forEach(row => {
					if (orderData3) {
						orderData3.forEach(item => {
							if (row.id == item.serve_id) {
								that.multipleSelection3.push(row);
							}
						});
					}
				});
				that.examination.forEach(row => {
					if (orderData4) {
						orderData4.forEach(item => {
							console.log(item);
							if (row.name == item.health_name) {
								that.multipleSelection4.push(row);
							}
						});
					}
				});
				that.toggleSelection1(that.multipleSelection1);
				that.toggleSelection2(that.multipleSelection2);
				that.toggleSelection3(that.multipleSelection3);
				that.toggleSelection4(that.multipleSelection4);
			},

			getPro() {
				//获取看诊项目
				return getDisease().then(res => {
					this.projectList = res;
				});
			},
			onProject() {
				//选择看诊项目
				console.log(this.pro_inx)
				this.disease_ids = this.pro_inx;
				this.disease_name = [];
				this.projectList.forEach(item => {
					if (this.disease_ids.indexOf(item.id) !== -1) {
						this.disease_name.push(item.name)
					}
				})
			},
			ongetClassify() {
				//获取诊疗分类
				let type = 2; //1代表咨询项目,2代表诊疗项目
				getClassify({
					type
				}).then(res => {
					this.dtClass = res;
				});
			},
			onGetDiagnosis() {
				//获取诊疗列表
				let categorys_id = this.categorys_id;
				let name = this.categorys_name;
				let page = this.dtPage;
				let limt = this.limt;
				getDiagnosis({
					categorys_id,
					name,
					page,
					limt
				}).then(res => {
					this.dtClassTotal = res.total;
					if (this.dtClassList.length > 0) {
						this.dtClassList = res.list;
						this.dtClassList.forEach(item => {
							item.showBar = true;
							res.list.forEach(items => {
								if (item.id == items.id) {
									item.showBar = false;
								}
							});
						});
					} else {
						this.dtClassList = res.list;
					}
				});
			},
			onGetMedicine() {
				//获取药品列表
				let type = this.categorys_type;
				let name = this.categorys_name2;
				let page = this.ypPage;
				let limt = this.limt;
				getMedicine({
					type,
					name,
					page,
					limt
				}).then(res => {
					this.drugsTotal = res.total;
					if (this.drugsList.length > 0) {
						this.drugsList = res.list;
						this.drugsList.forEach(item => {
							item.showBar = true;
							res.list.forEach(items => {
								if (item.id == items.id) {
									item.showBar = false;
								}
							});
						});
					} else {
						this.drugsList = res.list;
					}
				});
			},
			onGetExamination() {
				//获取药品列表
				let name = this.categorys_name4;
				let page = this.exPage;
				let limt = this.limt;
				getExamination({
					name,
					page,
					limt
				}).then(res => {
					console.log(res);
					this.examinationTotal = res.total;
					if (this.examination.length > 0) {
						this.examination = res.list;
						this.examination.forEach(item => {
							item.showBar = true;
							res.list.forEach(items => {
								console.log("isshow", item.id == items.id);
								if (item.id == items.id) {
									item.showBar = false;
								}
							});
						});
					} else {
						this.examination = res.list;
					}
				});
			},
			onVipServe() {
				// 会员服务列表
				let page = this.VipPage;
				let limt = this.limt;
				let name = this.categorys_name3;
				vipServe({
					page,
					limt,
					name
				}).then(res => {
					this.vipTotal = res.total;
					if (this.vipList.length > 0) {
						this.vipList = res.list;
						this.vipList.forEach(item => {
							item.showBar = true;
							res.list.forEach(items => {
								if (item.id == items.id) {
									item.showBar = false;
								}
							});
						});
					} else {
						this.vipList = res.list;
					}
				});
			},
			// 存病历模板
			saveTemplate(status) {
				this.templateIndex = status; //1：病历模板   2：接诊模板
				if (!this.disease_ids.length > 0) {
					this.$message("请选择看诊项目");
					return;
				}
				if (status === 1) {
					if (!this.recordInfo.physique) {
						this.$message("请填写体格检查");
						return;
					}
					if (!this.recordInfo.diagnose) {
						this.$message("请填写诊断");
						return;
					}
					if (!this.recordInfo.treatment) {
						this.$message("请填写治疗方案");
						return;
					}
					if (!this.recordInfo.announcements) {
						this.$message("请填写诊后注意事项");
						return;
					}
					(this.dialogFormVisible = true), (this.setStatus = 2);
				} else {
					if (this.orderData1.length > 0 || this.orderData2.length > 0) {
						(this.dialogFormVisible = true), (this.setStatus = 2);
					} else {
						this.$message("请选择诊疗药品");
					}
				}
			},
			onAddMedical() {
				if (!this.nameTemplate1) {
					this.$message("请输入模板名称");
					return;
				}
				if (this.templateIndex === 1) {
					let data = {
						name: this.nameTemplate1,
						disease_ids: this.disease_ids.toString(),
						disease_name: this.disease_name.toString(),
						appeal: this.recordInfo.appeal,
						now: this.recordInfo.now,
						before: this.recordInfo.before,
						physique: this.recordInfo.physique,
						diagnose: this.recordInfo.diagnose,
						treatment: this.recordInfo.treatment,
						announcements: this.recordInfo.announcements,
						msg: this.recordInfo.msg
					};
					addMedical(data).then(() => {
						//  新增病历模板
						this.dialogFormVisible = false;
						this.nameTemplate1 = "";
						this.$message.success("添加成功");
					});
				} else {
					if (this.orderData1.length > 0) {
						this.orderData1.map(item => {
							item.diagnosis_id = item.id;
							item.diagnosis_name = item.name;
						});
						let data1 = {
							name: this.nameTemplate1,
							disease_ids: this.disease_ids.toString(),
							disease_name: this.disease_name.toString(),
							data: this.orderData1
						};
						addDiagnosis(data1).then(() => {
							//  新增诊疗模板
							this.dialogFormVisible = false;
							this.nameTemplate1 = "";
							// console.log(res)
							this.$message.success("添加诊疗模板成功");
						});
					}
					if (this.orderData2.length > 0) {
						this.orderData2.map(item => {
							item.medicine_id = item.id;
						});
						let data2 = {
							name: this.nameTemplate1,
							disease_ids: this.disease_ids.toString(),
							disease_name: this.disease_name.toString(),
							data: this.orderData2
						};
						addDrug(data2).then(() => {
							//  新增药品模板
							this.dialogFormVisible = false;
							this.nameTemplate1 = "";
							// console.log(res)
							this.$message.success("添加药品模板成功");
						});
					}
				}
			},
			// 查病历模板、详情
			onGetMedical() {
				let page = this.page;
				let limt = this.limt;
				getMedical({
					page,
					limt
				}).then(res => {
					this.caseHistoryList = res.list;
					this.caseHistoryTotal = res.total;
				});
			},
			onMedicalDetails(id, status) {
				console.log(status);
				//status 1：调用   2：详情
				medicalDetails({
					id
				}).then(res => {
					if (status == 2) {
						this.caseHistoryInfo = [{
								name: "看诊项目",
								content: res.disease_name
							},
							{
								name: "主诉",
								content: res.appeal
							},
							{
								name: "现病史",
								content: res.now
							},
							{
								name: "既往史",
								content: res.before
							},
							{
								name: "体格检查",
								content: res.physique
							},
							{
								name: "诊断",
								content: res.diagnose
							},
							{
								name: "治疗方案",
								content: res.treatment
							},
							{
								name: "诊后注意事项",
								content: res.announcements
							},
							{
								name: "备注",
								content: res.msg
							}
						];
					} else {
						this.pro_inx = res.disease_ids.map(item => Number(item))
						this.disease_ids = res.disease_ids.map(item => Number(item))
						this.disease_name = res.disease_name.split(',')

						// this.pro_inx = [];
						// this.projectList.map((item) => {
						//   res.disease_ids.map(items => {
						//     if (item.id == items) {
						//       this.pro_inx.push(item.id);
						//     }
						//   });
						// });
						// this.onProject();
						// this.pro_inx = res.disease_ids
						this.recordInfo = res;
					}
				});
			},
			// 接诊模板详情
			onDiagnosisInfo(id, status) {
				console.log(this.activeType);

				diagnosisDrugs({
					id
				}).then(res => {
					if (status == 2) {
						this.templateInfo = res;
					} else {
						if (this.activeType === "1") {

							this.toggleSelection1(this.orderData1, false);

							this.orderData1 = res;

							// this.toggleSelection1(this.orderData1);

							let arr = [];

							this.$nextTick(() => {
								this.dtClassList.forEach(row => {
									if (this.orderData1) {
										this.orderData1.forEach(item => {
											if (row.id == item.ids) {
												row.counts = item.counts;
												arr.push(row);
											}
										});
									}
								});

								// this.orderData1 = this.multipleSelection1;

								this.toggleSelection1(arr);
							})

						} else {
							res.map(item => {
								item.medicine_name = item.name;
							});


							this.toggleSelection2(this.orderData2, false);

							this.orderData2 = res;

							this.orderData2.forEach(v => {
								v.id = v.ids
							})

							this.toggleSelection2(this.orderData2);
						}
					}
				});
			},

			// 查看治疗模板
			seeTemplate() {
				this.page = 1;
				this.seeTemplate1();
				this.seeTemplate2();
			},
			seeTemplate1() {
				let data1 = {
					page: this.page,
					limt: this.limt,
					type: 1
				};
				diagnosisDrug(data1).then(res => {
					this.templateData1 = res.list;
					this.templateTotal1 = res.total;
				});
			},
			seeTemplate2() {
				let data2 = {
					page: this.page,
					limt: this.limt,
					type: 2
				};
				diagnosisDrug(data2).then(res => {
					this.templateData2 = res.list;
					this.templateTotal2 = res.total;
				});
			},
			// 删除
			onDelMedical(id, status) {
				//status 1:病历模板  2：接诊模板
				if (status == 1) {
					delMedical({
						id
					}).then(() => {
						// console.log(res)
						this.$message.success("删除成功");
						this.page = 1;
						this.onGetMedical();
					});
				} else {
					delTemplate({
						id
					}).then(() => {
						this.$message.success("删除成功");
						this.seeTemplate();
					});
				}
			},

			// 选中分类
			dtClassChange(val) {
				const _ids = [];
				if (val.length >= 2) {
					for (let i = 0; i < val.length - 1; i++) {
						for (let j = i + 1; j < val.length; j++) {
							console.log("i-j", `${i}-${j}`);
							if (val[i].id === val[j].id) {
								_ids.push(val[i].id);
							}
						}
					}
				}
				this.orderData1 = val;
				if (_ids.length > 0) {
					let index = this.orderData1.findIndex(v => _ids.includes(v.id));
					while (index != -1) {
						this.deleteRow1(index, this.orderData1);
						index = this.orderData1.findIndex(v => _ids.includes(v.id));
					}
				}
			},
			dtClassChange2(val) {
				const _ids = [];
				if (val.length >= 2) {
					for (let i = 0; i < val.length - 1; i++) {
						for (let j = i + 1; j < val.length; j++) {
							console.log("i-j", `${i}-${j}`);
							if (val[i].id === val[j].id) {
								_ids.push(val[i].id);
							}
						}
					}
				}
				this.orderData2 = val;
				if (_ids.length > 0) {
					let index = this.orderData2.findIndex(v => _ids.includes(v.id));
					while (index != -1) {
						this.deleteRow2(index, this.orderData2);
						index = this.orderData2.findIndex(v => _ids.includes(v.id));
					}
				}
			},
			dtClassChange3(val) {
				const _ids = [];
				if (val.length >= 2) {
					for (let i = 0; i < val.length - 1; i++) {
						for (let j = i + 1; j < val.length; j++) {
							console.log("i-j", `${i}-${j}`);
							if (val[i].id === val[j].id) {
								_ids.push(val[i].id);
							}
						}
					}
				}
				this.orderData3 = val;
				if (_ids.length > 0) {
					let index = this.orderData3.findIndex(v => _ids.includes(v.id));
					while (index != -1) {
						this.deleteRow3(index, this.orderData3);
						index = this.orderData3.findIndex(v => _ids.includes(v.id));
					}
				}
			},
			dtClassChange4(val) {
				const _ids = [];
				if (val.length >= 2) {
					for (let i = 0; i < val.length - 1; i++) {
						for (let j = i + 1; j < val.length; j++) {
							if (val[i].id === val[j].id) {
								_ids.push(val[i].id);
							}
						}
					}
				}
				this.orderData4 = val;
				if (_ids.length > 0) {
					let index = this.orderData4.findIndex(v => _ids.includes(v.id));
					while (index != -1) {
						this.deleteRow4(index, this.orderData4);
						index = this.orderData4.findIndex(v => _ids.includes(v.id));
					}
				}
			},
			toggleSelection1(rows, status = true) {
				if (rows.filter(v => v).length > 0) {
					let _rows = [];
					rows.forEach(row => {
						const res = this.dtClassList.find(v => v.id === row.id);
						res && _rows.push(res);
					});
					if (_rows.length > 0) {
						_rows.forEach(row => {
							this.$refs.multipleTable1.toggleRowSelection(row, status);
						});
					} else {
						rows.forEach(row => {
							this.$refs.multipleTable1.toggleRowSelection(row, status);
						});
					}
				} else {
					this.$refs.multipleTable1.clearSelection();
				}
			},
			toggleSelection2(rows, status = true) {
				console.log(rows);

				console.log(this.drugsList);
				if (rows.filter(v => v).length > 0) {
					let _rows = [];
					rows.forEach(row => {
						const res = this.drugsList.find(v => v.id === row.id);
						res && _rows.push(res);
					});
					console.log('----');
					console.log(_rows);
					if (_rows.length > 0) {
						_rows.forEach(row => {
							this.$refs.multipleTable2.toggleRowSelection(row, status);
						});
					} else {
						rows.forEach(row => {
							this.$refs.multipleTable2.toggleRowSelection(row, status);
						});
					}
				} else {
					this.$refs.multipleTable2.clearSelection();
				}
			},
			toggleSelection3(rows, status = true) {
				if (rows.filter(v => v).length > 0) {
					let _rows = [];
					rows.forEach(row => {
						const res = this.vipList.find(v => v.id === row.id);
						res && _rows.push(res);
					});
					if (_rows.length > 0) {
						_rows.forEach(row => {
							this.$refs.multipleTable3.toggleRowSelection(row, status);
						});
					} else {
						rows.forEach(row => {
							this.$refs.multipleTable3.toggleRowSelection(row, status);
						});
					}
				} else {
					this.$refs.multipleTable3.clearSelection();
				}
			},
			toggleSelection4(rows, status = true) {
				if (rows.filter(v => v).length > 0) {
					let _rows = [];
					rows.forEach(row => {
						const res = this.examination.find(v => v.id === row.id);
						res && _rows.push(res);
					});
					if (_rows.length > 0) {
						_rows.forEach(row => {
							this.$refs.multipleTable4.toggleRowSelection(row, status);
						});
					} else {
						rows.forEach(row => {
							this.$refs.multipleTable4.toggleRowSelection(row, status);
						});
					}
				} else {
					this.$refs.multipleTable4.clearSelection();
				}
			},
			// 控制类型切换显示隐藏
			tableRowClassName: function({
				row
			}) {
				if (row.showBar) {
					return "hidden-row";
				}
				return "";
			},

			// 添加处方药品
			// addPro(){
			//     console.log(this.activeIndex)
			//     if(this.activeIndex==="1"){

			//     }
			// },

			// 添加和删除疗程设置
			addClick(ind) {
				console.log(ind);
				let obj = {
					date: ""
				};
				this.course.push(obj);
			},
			delClick(ind) {
				this.course.splice(ind, 1);
			},
			// 日期截取
			handleDate() {
				this.course.map(item => {
					if (item.date) {
						const d = new Date(item.date);
						item.date =
							d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
					}
				});
			},

			// 接诊
			onSubmit() {
				console.log("submit!");
				// console.log('病历信息：',this.recordInfo)
				// console.log('体格信息：',this.physiques)

				// console.log('其他数据：',this.other)
				// console.log('就诊人id：',this.userData.info_id)
				console.log("看诊项目表id", this.disease_ids.toString());
				console.log("看诊项目名称", this.disease_name.toString());
				// console.log('治疗费',this.getCureMoney)
				// console.log('药品费',this.getDrugsMoney)
				// console.log('会员服务费',this.getMemberMoney)
				// console.log('总金额',this.getCureMoney+this.getDrugsMoney+this.getMemberMoney)

				// console.log('评分表', this.score)
				// console.log('诊疗单数据', this.orderData1)
				// console.log('药品单数据', this.orderData2)
				// console.log('会员服务数据', this.orderData3)

				if (!this.userData.info_id) {
					this.$message.error("请先选择就诊人");
					return;
				}
				this.$refs["recordInfo"].validate(valid => {
					if (!valid) {
						this.$message("请填写病历信息");
					} else {
						if (!this.disease_ids.length > 0) {
							this.$message.error("请先选择看诊项目");
							return;
						}
						if (!this.orderData1.length > 0) {
							this.$message.error("请填写诊疗单");
							return;
						}
						// if(!this.orderData2.length>0){
						//     this.$message.error("请填写药品单")
						//     return
						// }
						this.$msgbox({
								title: "提示",
								message: h("div", null, [
									h(
										"p",
										null,
										this.course.length == 1 || !this.score.length > 0 ?
										"检测到您有以下内容未填写，是否确定提交该服务单？" :
										"是否确认提交该服务单"
									),
									h(
										"p", {
											style: "color: teal"
										},
										this.course.length == 1 ? "疗程设置" : ""
									),
									h(
										"p", {
											style: "color: teal"
										},
										!this.orderData2.length > 0 ? "药品单" : ""
									),
									h(
										"p", {
											style: "color: teal"
										},
										this.score.length > 0 ? "" : "评分表"
									)
								]),
								showCancelButton: true,
								closeOnClickModal: false,
								showClose: false,
								confirmButtonText: "提交",
								cancelButtonText: "继续填写"
							})
							.then(() => {
								this.postData();
							})
							.catch(() => {});
					}
				});
			},
			postData() {
				let id = this.id;
				if (!this.recordInfo.remote_views) {
					this.recordInfo.remote_views = "";
				}
				let arr = {
					physique: this.physiques, //体格信息数据
					medical: this.recordInfo, //病历信息数据
					other: {
						//其他数据
						info_id: this.userData.info_id, //就诊人id
						disease_ids: this.disease_ids.toString(), //看诊项目表id
						disease_name: this.disease_name.toString(), //看诊项目名称
						type: this.other.type, //1代表初诊，2代表复诊
						symptom: this.other.symptom, //症状
						diagnosis_cost: this.getCureMoney, //治疗费
						drugs_cost: this.getDrugsMoney, //药品费
						vip_cost: this.getMemberMoney, //会员服务费
						health_money: this.getExaminationMoney, //获取体检金额
						count_cost: this.totalMoney //总金额
					},
					score: this.score, //评分表数据
					diagnosis: this.orderData1, //诊疗单数据
					drug: this.orderData2, //药品单数据
					vip: this.orderData3, //会员服务数据
					health: this.orderData4, //体检数据
					course: this.course, //疗程设置数据
					diagnosis_msgs: this.markupTreament,
					drug_msgs: this.markupMedicine,
				};
				if (this.types == 1) {
					clinicalReception({
						arr,
						id
					}).then(() => {
						this.$notify({
							title: "提示",
							message: "提交成功",
							type: "success"
						});
						this.$router.replace({
							path: "/curativeList"
						});
					});
				} else {
					editOrder({
						arr,
						id
					}).then(() => {
						this.$notify({
							title: "提示",
							message: "编辑成功",
							type: "success"
						});
						this.$router.replace({
							path: "/curativeList"
						});
					});
				}
			},

			// 评分表
			onScoring(data) {
				this.$router.push({
					path: "/newScoring",
					query: {
						data: JSON.stringify(data)
					}
				});
			},

			// 编辑操作
			handleEdit(row) {
				this.form = row;
				this.$router.push({
					path: "/memberInfo",
					query: {
						userid: row.id
					}
				});
			},

			// 诊疗分页导航
			dtPageChange(val) {
				// this.$set(this.query, "page", val);
				this.dtPage = val;
				this.onGetDiagnosis();
			},
			ypPageChange(val) {
				this.ypPage = val;
				this.onGetMedicine();
			},
			examinationPageChange(val) {
				this.exPage = val;
				this.onGetExamination();
			},
			vipPageChange(val) {
				this.VipPage = val;
				this.onVipServe();
			},
			handleTab() {
				this.page = 1;
			},
			casePageChange(val) {
				this.page = val;
				this.onGetMedical();
			}
		}
	};
</script>
<style>
	.el-table .hidden-row {
		display: none;
	}
</style>
<style scoped>
	.delete-proj-btn {
		background-color: #F56C6C;
		border: 1px solid #F56C6C;
		height: 30px;
		line-height: 30px;
		width: 30px;
		color: #FFFFFF;
		text-align: center;
		font-size: 14px;
		border-radius: 50%;
		margin: 6px 0 0 8px;
	}

	.demo-ruleForm {
		width: 100%;
	}

	.basics {
		display: flex;
		width: 60%;
		justify-content: space-between;
	}

	.title,
	.box {
		margin-bottom: 20px;
	}

	.box,
	.infoBox {
		display: flex;
	}

	.infoBoxLeft,
	.infoBoxRight {
		width: 45%;
	}

	.infoBoxLeft {
		margin-right: 5%;
	}

	.infoBoxRight {
		border-left: 1px solid #ccc;
		padding-left: 4%;
		box-sizing: border-box;
	}

	.linkWrap {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}

	.buttonText {
		margin: 0;
	}

	.linkBar {
		margin-top: 20px;
		text-align: left;
	}

	.textarea {
		display: block;
		margin-left: 0;
	}

	.line {
		margin-left: 10px;
	}

	.infoBoxBtn {
		justify-content: space-between;
	}

	/* 价格 */
	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.containerLeft {
		display: flex;
		align-items: center;
		padding-left: 30px;
	}

	.containerLeft_text {
		margin-right: 80px;
		font-size: 16px;
		color: #606266;
	}

	.containerLeft_text span {
		font-weight: bold;
		font-size: 18px;
	}

	.physiqueInfo {
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 20px;
		padding-bottom: 5px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.title {
		position: relative;
	}

	.mark-btn {
		position: absolute;
		right: 0;
	}

	.markup-input {
		width: 100%;
		height: 300px;
		font-size: 18px;
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		padding: 10px;
		border-radius: 5px;
	}

	.markup-input:focus {
		border-color: #409EFF;
		outline: 0;
	}
</style>
